.app {
  width: 100%;
}

.site-header {
  padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  background-color: #160b0d;
  height: 65px;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand {
  display: block;
  width: 80px;
  /*
  display: block;
  text-decoration: none;
  color: #ffdb00;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  padding: 0px 4px 9px 4px;
  border: 1px solid #ffdb00;
  border-radius: 1px;
  line-height: 0;
  */
}

.brand h1 {
  font-size: 1.5em;
  margin-bottom: 2px;
}
.brand span {
  font-size: 0.9em;
  font-weight: 600;
  display: block;
  margin-top: 3px;
  color: #ffffe0;
}

.menu-wrapper {
  position: relative;
  border-radius: 2px;
  width: 36px;
  border: 1px solid #ffdb00;
}
#toggle-responsive-menu {
  display: none;
}
.nav-label {
  display: block;
  color: #ffdb00;
  font-weight: 600;
  padding: 2px;
  font-size: 35px;
  line-height: 0;
  height: 36px;
  width: 38px;
  padding-top: 16px;
  border-radius: 2px;
  cursor: pointer;
}

.nav-inner {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #160b0d;
  width: 180px;
  text-align: center;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
  display: none;
}

.hide-menu {
  width: 100%;
  display: flex;
  justify-content: center;
}
.hide-menu-btn {
  color: #ffdb00;
  font-size: 24px;
  box-shadow: none;
  background-color: transparent;
  line-height: 0;
  padding: 0;
  border: 1px solid #ffdb00;
  width: 30px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  margin-bottom: 10px;
  border-radius: 0;
}
.hide-menu-btn:hover {
  color: rgba(86, 103, 122, 1);
  border: 1px solid rgba(86, 103, 122, 1);
}

.site-menu {
  display: block;
  width: 100%;
}

.site-menu li {
  padding: 10px 0;
  border-bottom: 1px solid #ffffe0;
}

.site-menu li a {
  color: #ffffe0;
  text-decoration: none;
}
.site-menu li a:hover,
.site-menu li a:focus {
  color: #ffdb00;
}

.showParent {
  display: block;
  animation: animatezoom 1s;
}

.footer-menu li {
  margin: 0 20px;
  position: relative;
  font-family: "Sohn Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.footer-menu li a {
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  color: #160b0d;
}

.footer-menu li:hover a,
.footer-menu li:focus a {
  color: #ffffe0;
}
.footer-menu li:after {
  content: "";
  position: absolute;
  top: 100%;
  height: 2px;
  background-color: #ffffe0;
  width: 100%;
  left: 0;
  opacity: 0;
  transition: 0.3s ease;
  border-radius: 1em;
}
.footer-menu li:hover:after {
  opacity: 1;
}
.footer-menu {
  display: flex;
  justify-content: space-between;
}
.footer-menu li a {
  color: #ffffe0;
}

.hero-content-container {
  text-transform: uppercase;
  position: absolute;
  top: 20%;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.hero,
.page-hero {
  height: 100vh;
  overflow: hidden;
  position: relative;
  top: 65px;
  margin-bottom: 65px;
}
.page-hero {
  height: 450px;
}
.curtain:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.best-bars-container {
  align-items: center;
  justify-content: center;
}

.best-star {
  width: 20px;
}
.best-bar {
  width: 100px;
  height: 3px;
  background-color: #ffdb00;
}
.brand-section h2 {
  font-size: 5em;
  color: #ffdb00;
}

.cta-section {
  justify-content: space-between;
  margin-top: 50px;
}

.cta-section .button {
  width: 230px;
  padding: 16px;
}

.best-text {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  color: #fff;
}

.slider-items-container {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  z-index: 2;
}
.sliderImg {
  width: 100%;
}

.slider-items-container .slick-arrow {
  background-color: transparent !important;
  border-radius: 1px;
  height: 30px;
  padding-top: 3px;
  width: 40px;
  top: 100%;
}
.slider-items-container .slick-arrow {
  background-color: #000 !important;
}
.slider-items-container .slick-arrow:hover {
  background-color: #555 !important;
}

.slider-items-container .slick-next,
.slider-items-container .slick-prev {
  z-index: 100;
}
.slick-dots {
  bottom: 0 !important;
}
.carousel .slider-items-container.center-slide-arrows .slick-arrow {
  top: 50% !important;
}
.carousel .slider-items-container .slick-next {
  right: 30px;
}

.carousel .slider-items-container .slick-prev {
  left: 30px;
}

.page-hero .slider-items-container.center-slide-arrows .slick-arrow {
  top: 30% !important;
}

.contacts-container {
  justify-content: space-between;
}
.contacts-head {
  text-transform: uppercase;
  align-items: center;
}
.contacts-head h4 {
  font-size: 20px;
  border-bottom: 2px solid #160b0d;
}
.contacts-body {
  padding: 40px 0;
  padding-bottom: 0;
  margin-left: 40px;
}
.contacts-icon {
  width: 30px;
  margin-right: 10px;
}
.contacts-body p {
  font-size: 1.1em;
}
.social-icon {
  display: block;
  border: 1px solid #160b0d;
  padding: 5px;
  border-radius: 1px;
  margin-right: 16px;
  padding-top: 3px;
}
.social-icon:hover,
.social-icon:focus {
  border: 1px solid #ffdb00;
}

.social-icon img {
  width: 20px;
}

.section-title {
  text-transform: uppercase;
}

.about-content p {
  font-size: 1.2em;
  margin: 10px 0;
}
.about-content h3 {
  margin-top: 40px;
}
.pageSliderImg {
  width: 100%;
}

.menu-cards-container,
.room-cards-container {
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

.menu-card,
.room-card {
  width: 300px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #ffdb00;
  margin: 16px;
  cursor: pointer;
}
.room-card {
  width: 500px;
}
.room-title {
  padding: 16px 0;
  text-transform: uppercase;
  font-size: 1.5em;
}
.room-details {
  padding: 20px 8px;
  text-align: center;
}
.facility-card .room-details ul {
  text-align: left;
  list-style-type: disc;
}
.room-cta {
  padding: 16px;
  font-size: 1.2em;
  border-bottom: 2px solid transparent;
}
.room-cta:hover {
  border-bottom: 2px solid #160b0d;
}
.images-slide .gallery-slider img {
  width: 100%;
  height: 300px;
  object-position: bottom !important;
}
.menu-title h3 {
  font-size: 1.5em;
  padding: 10px;
}

.menu-items li {
  margin-bottom: 20px;
  padding: 10px;
}
.menu-items li:last-child {
  margin-bottom: 0;
}
.menu-items li:nth-child(even) {
  background-image: url("./assets/images/background/wave.svg");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top;
}
.menu-items h4 {
  font-size: 1.2em;
}

.menu-items p {
  margin: 10px 0;
  text-align: justify;
}
.menu-image img {
  object-fit: contain;
  width: 100%;
  transition: 0.5s all ease-in;
  max-width: 600px;
}
.menu-card:hover .menu-image img {
  transform: scale(1.5);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #ffdb00;
}

.map-content iframe {
  width: 100%;
  height: 300px;
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
  .contacts-head h4,
  .contacts-body p {
    font-size: 1em;
  }

  .sliderImg,
  .pageSliderImg {
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
  }

  .page-hero,
  .pageSliderImg {
    height: 60vh;
  }
}
@media screen and (min-width: 800px) and (max-width: 959px) {
  .hero {
    height: 100vh;
  }
  .sliderImg,
  .pageSliderImg {
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
  }

  .page-hero,
  .pageSliderImg {
    height: 50vh;
  }
  .contacts-container {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .contacts-item {
    margin: 20px 0;
  }
  .width-800 {
    max-width: 800px;
    width: 100%;
  }
  .brand-section h2 {
    font-size: 4em;
  }
  .hero,
  .page-hero {
    height: 100%;
  }
  .carousel .slider-items-container.center-slide-arrows .slick-arrow,
  .page-hero .slider-items-container.center-slide-arrows .slick-arrow {
    top: 80% !important;
  }
  .map-content {
    width: 100%;
    text-align: center;
  }
  .map-content iframe {
    width: 100%;
  }
  .width-960 {
    max-width: 959px;
    width: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: 799px) {
  .hero {
    height: 100vh;
  }
  .width-600 {
    max-width: 600px;
    width: 100%;
  }
  .sliderImg,
  .pageSliderImg {
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
  }

  .page-hero,
  .pageSliderImg {
    height: 50vh;
  }
  .contacts-container {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .contacts-item {
    margin: 20px 0;
    width: 280px;
  }
  .width-800 {
    max-width: 600px;
    width: 100%;
  }
  .brand-section h2 {
    font-size: 4em;
  }
  .footer-copyright {
    justify-content: center !important;
  }
  .carousel .slider-items-container.center-slide-arrows .slick-arrow,
  .page-hero .slider-items-container.center-slide-arrows .slick-arrow {
    top: 80% !important;
  }

  .map-content {
    width: 100%;
    text-align: center;
  }
  .map-content iframe {
    width: 100%;
  }
  .width-960 {
    max-width: 799px;
    width: 100%;
  }
}
@media screen and (max-width: 599px) {
  .hero {
    height: 100vh;
  }

  .width-600 {
    max-width: 599px;
    width: 100%;
  }
  .contacts-container {
    justify-content: center;
    flex-wrap: wrap;
  }

  .cta-section {
    flex-wrap: wrap;
    justify-content: center;
    flex-flow: column;
  }

  .cta-section .button {
    margin: 10px auto;
    width: 100%;
    max-width: 300px;
  }
  .sliderImg,
  .pageSliderImg {
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
  }

  .page-hero,
  .pageSliderImg {
    height: 50vh;
  }

  .contacts-item {
    margin: 20px 0;
    width: 280px;
  }
  .width-800 {
    max-width: 599px;
    width: 100%;
  }
  .brand-section h2 {
    font-size: 3em;
  }
  .design-inner {
    margin-top: 10px;
  }

  .footer-copyright {
    justify-content: center !important;
    font-size: 12px;
  }
  .carousel .slider-items-container.center-slide-arrows .slick-arrow,
  .page-hero .slider-items-container.center-slide-arrows .slick-arrow {
    top: 90% !important;
  }
  .slogan-section {
    width: 300px;
    margin: auto;
  }
  .footer-menu {
    flex-flow: column;
    justify-content: center;
  }

  .footer-menu li {
    width: fit-content;
    margin: 10px auto;
  }
  .room-card {
    width: 300px;
  }
  .images-slide .gallery-slider img {
    height: 200px;
  }
  .width-300 {
    width: 100%;
  }
  .menu-image img {
    max-width: 400px;
  }
  .menu-card:hover .menu-image img {
    transform: scale(1.2);
  }
  .slogan-section {
    width: 260px;
    margin-top: 20px;
  }
  .map-content {
    width: 100%;
    text-align: center;
  }
  .map-content iframe {
    width: 100%;
  }
  .width-960 {
    max-width: 599px;
    width: 100%;
  }
}
